@import url('https://fonts.googleapis.com/css2?family=Fira+Sans+Extra+Condensed:wght@300;400;800&display=swap');

@font-face {
    font-family: 'Gilroy-ExtraBold';
    src: url('/public/fonts/Gilroy-ExtraBold.otf');
}

@font-face {
    font-family: 'Gilroy-Light';
    src: url('/public/fonts/Gilroy-Light.otf');
}

@font-face {
    font-family: 'Smooch-Regular';
    src: url('/public/fonts/Smooch-Regular.ttf');
}

:root {
    --gradient: linear-gradient(146deg, #85fade 15%, #7badf9 55%, #3662a4);
    --primary: #000000;
    --secondary: #7badf9;
}

body {
    margin: 0;
    padding: 0;
}

*::-webkit-scrollbar {
    width: 5px !important;
}

*::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0) !important;
}

*::-webkit-scrollbar-thumb {
    background-color: var(--secondary) !important;
    outline: 0px solid rgba(112, 128, 144, 0) !important;
    border-radius: 100px;
}

body * {
    font-family: 'Fira Sans Extra Condensed', sans-serif, sans-serif;
}

body :where(h1, h2, h3, .bold) {
    font-family: 'Gilroy-ExtraBold' !important;
}

body :where(.light) {
    font-family: 'Gilroy-Light' !important;
}

body :where(.script) {
    font-family: 'Smooch-Regular' !important;
}

.maxHeight100 {
    max-height: 100vh;
}

.overflowY-scroll {
    overflow-y: scroll !important;
    overflow-x: hidden;
}

.overflowHidden {
    overflow: hidden !important;
}

a {
    text-decoration: none !important;
}


div.logo img {
    width: 150px;
}

div.logo-icon img {
    width: 30px;
}

.border-radius-20 {
    border-radius: 20px !important;
}

.border20 {
    border-radius: 20px !important;
}

.margin0 {
    margin: 0px !important;
}

.pointer {
    cursor: pointer;
}

::selection {
    background: var(--primary);
    color: white;
}

.hoverup {
    transition: .5s ease;
}

.fixed {
    position: fixed;
    left: 0;
}

.bottom0 {
    bottom: 0;
}

.top0 {
    top: 0;
}

.glass {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.155);
}

.hoverup:hover {
    transform: translateY(-5px);
}

.primary {
    background-color: var(--primary);
}

.secondary {
    background-color: var(--secondary);
}

.gradient {
    background: var(--gradient);
}

.gradient-animate {
    background: linear-gradient(146deg, #000000, #000000);
    background-size: 400%;
    animation: gradient 20s infinite;
}

.neumorphisim {
    background: #ffffff;
    box-shadow: 20px 20px 60px #d9d9d9, -20px -20px 60px #ffffff;
}

@keyframes gradient {

    0%,
    100% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }
}

.mid {
    width: 600px;
}

.wide {
    width: 800px;
}

.small {
    width: 300px;
}

.full {
    height: 100vh;
}

.fullwidth {
    width: 100%;
}

.hide {
    display: none;
}

.shadow {
    box-shadow: 0px 10px 20px #f0f0f0;
}

.margin-auto {
    margin: 0px auto !important;
}

.hide-scrollbar::-webkit-scrollbar {
    width: 0em !important;
}

.hide-scrollbar::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0) !important;
}

.hide-scrollbar::-webkit-scrollbar-thumb {
    background-color: rgba(169, 169, 169, 0) !important;
    outline: 0px solid rgba(112, 128, 144, 0) !important;
}

.testimonial {
    position: relative;
}

.testimonial * {
    z-index: 2;
}

.testimonial::after {
    content: '';
    height: 60px;
    width: 60px;
    background-image: url('/public/assets/quotes.png');
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    top: -30px;
    left: -60px;
    z-index: 0;
    opacity: .5;
}

.seventy-percent {
    width: 70% !important;
}

.custom-menu {
    background-color: rgb(255, 255, 255);
    border-radius: 16px;
    position: fixed;
    right: 10px;
    z-index: 20;
    box-shadow: 10px 10px 20px #e0e0e0;
    width: 370px;
}

.modal {
    position: fixed;
    top: 150px;
    left: calc(50% - (600px/2));
    z-index: 7;
    height: 400px;
    overflow: hidden;
}

code {
    /* overflow-x: hidden !important; */
    padding: 20px !important;
    border-radius: 10px;
}

/* QUILL additional styling */
.quill {
    border-radius: 20px;
    overflow: hidden;
}

.ql-editor {
    min-height: 200px;
    max-height: 500px;
    border: 0px solid transparent;
    background-color: #f0f0f0;
}

.ql-container.ql-snow {
    border: 0px solid transparent !important;
}

.ql-toolbar.ql-snow {
    border: 0px solid transparent !important;
    background-color: #f0f0f0;

}

/* horizontal scroll */
.horizontal-scroll {
    overflow-x: scroll;
}

.horizontal-scroll::-webkit-scroll {
    width: 0px !important;
}

.horizontal-scroll::-webkit-scrollbar-thumb {
    background-color: transparent !important;
    outline: 0px solid rgba(112, 128, 144, 0) !important;
    border-radius: 100px;
    height: 2px !important;
}

/* dashboard */
.slide-bar-bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.286);
    z-index: 10;
    opacity: 0;
    transition: .5s ease;
}

.slide-bar-bg>div {
    position: fixed;
    top: 10px;
    right: 10px;
    height: calc(100% - 60px);
    /* 60px = 40px top and bottom margin + 20px top and bottom space */
    min-width: 400px;
    width: 400px;
    background-color: white;
    border-radius: 20px;
    padding: 20px;
    overflow-y: scroll;
    transform: translateX(1000px);
    transition: .5s ease;
}

.slide-bar-bg.show {
    opacity: 1;
    transition: .5s ease;
}

.slide-bar-bg>div.show {
    transition: .5s ease;
    transform: translateX(0px);
}

.plugin {
    border-radius: 20px;
    overflow: hidden;
    transition: .5s ease;
    cursor: pointer;
    background: #fff;
    box-shadow: 20px 20px 30px #bebebe,
        -20px -20px 30px #ffffff;
    outline: 2px solid transparent;
}

.plugin.css:hover {
    outline: 2px solid #22EA87;
    transform: translateY(-10px);
}

.plugin.javascript:hover {
    outline: 2px solid #8B001C;
    transform: translateY(-10px);
}

.plugin-content {
    padding: 10px;
}

.plugin-image {
    height: 300px;
    overflow: hidden;
}

.plugin-image img {
    height: 100%;
    width: 150%;
    transition: .5s ease;
}

.plugin:hover .plugin-image img {
    transform: scale(1.2);
}

.preview-contract {
    border-radius: 20px;
    box-shadow: 10px 10px 20px #00000021;
    background-color: #EFF1F6;
    padding: 20px;
}

.document-preview-body {
    max-height: 700px;
    overflow-y: scroll;
}

.not-found-text {
    font-size: 40px !important;
    margin: 0px !important;
    display: inline-block;
    padding-right: 10px;
    animation: cursor 1s infinite linear;
}

@keyframes cursor {
    from {
        border-right: 2px solid black;
    }

    to {
        border-right: 2px solid transparent;
    }
}

.mozilla-zoom {
    font-size: 5px !important;
}


.preview-box {
    background: #0000003a;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 20;
    width: 100%;
    height: 100vh;
}

.preview {
    background-color: white;
    width: 600px;
    border-radius: 20px;
    padding: 20px;
}

.doc-preview {
    background-color: #f1f1f1;
    border-radius: 10px;
    padding: 10px;
    max-height: 300px;
    overflow-y: scroll;

}

input.tutorial-search {
    padding: 20px 30px !important;
    width: calc(100% - 60px) !important;
}

input.tutorial-search::placeholder {
    color: #0000003a;
    font-weight: 100 !important;
}

.tutorial {
    padding: 30px;
    border-radius: 20px;
    background-color: var(--primary);
    cursor: pointer;
    transition: .5s ease;
}

.tutorial:hover {
    transform: translateY(-5px);
}

.tutorial .play-btn {
    transition: .5s ease;
}

.tutorial:hover .play-btn {
    transform: rotate(360deg);
}

.popup {
    width: 100%;
    height: 100vh;
    background-color: #00000020;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 0;
    top: 0;
}

.popup iframe {
    border-radius: 20px;
    border: 5px solid var(--secondary);
    box-shadow: 0px 0px 20px #7badf9;
    overflow: hidden;
    aspect-ratio: 16/9;
    background-color: var(--secondary);
}

input.checkbox {
    width: fit-content !important;
}

.cust-list {
    padding: 20px;
    width: calc(100% - 40px);
    border-radius: 10px;
    background-color: #f0f0f0;
}

@media screen and (max-width: 900px) {
    .remove-grid-med-nd-down {
        display: block !important;
    }

    .hide-on-med-and-down {
        display: none !important;
    }

    .mid, .wide {
        width: 100%;
    }

    .suitable-width {
        width: 100%;
    }

    .seventy-percent {
        width: 100% !important;
    }

    .custom-menu {
        bottom: 100px !important;
        width: calc(100% - 60px) !important;
    }

    .custom-menu .url {
        font-size: 12px !important;
    }

    .custom-menu small {
        font-size: 5px !important;
    }

    /* dashboard */
    .slide-bar-bg>div {
        min-width: calc(95% - 40px) !important;
        width: calc(95% - 40px) !important;
        right: 2.5% !important;
    }

    /* manage document */
    .cust-btn {
        font-size: 12px !important;
        padding: 10px 30px !important;
    }

    .css-17fecvr-MuiDataGrid-root * {
        font-size: 16px !important;
    }

    /* templates */
    .preview {
        width: calc(95% - 20px) !important;
        padding: 10px !important;
        margin: auto;
    }

    /* tutorial */
    .tutorial-body .preview {
        width: calc(100%) !important;
        padding: 0px !important;
    }
}

@media screen and (min-width: 900px) {
    .hide-on-large-only {
        display: none !important;
    }

    .custom-menu {
        top: 120px;
    }

}